import { graphql } from "gatsby"
import React from "react"
import Footer from "../components/footer"
import GridItemGallery from "../components/grid-item-gallery"
import Header from "../components/header"
import SEO from "../components/seo"
import { getRandomDelay, getRandomDuration } from "../utils/utils"

// import "../styles/index.scss"
export default class IndexTemplate extends React.Component {
  render() {
    const {
      description,
      title,
      imagesLeft,
      imagesTopMid,
      imagesTopRight,
      imagesBottomMid,
      imagesBottomRight,
      metaImage,
      // featured_image,
    } = {
      ...this.props.data.markdownRemark.frontmatter,
    }
    return (
      <div>
        {/* {console.log("featured_image", this.props.data.markdownRemark)} */}
        <SEO title={title} description={description} metaImage={metaImage} />
        <Header></Header>
        <main className="grid grid-col-1-2-3">
          <div className="grid-item-1x2">
            <GridItemGallery
              images={imagesLeft}
              delay={getRandomDelay()}
              duration={getRandomDuration()}
            ></GridItemGallery>
          </div>
          <div className="grid-item-1x1">
            <GridItemGallery
              images={imagesTopMid}
              delay={getRandomDelay()}
              duration={getRandomDuration()}
            ></GridItemGallery>
          </div>
          <div className="grid-item-1x1">
            <GridItemGallery
              images={imagesTopRight}
              delay={getRandomDelay()}
              duration={getRandomDuration()}
            ></GridItemGallery>
          </div>
          <div className="grid-item-1x1">
            <GridItemGallery
              images={imagesBottomMid}
              delay={getRandomDelay()}
              duration={getRandomDuration()}
            ></GridItemGallery>
          </div>
          <div className="grid-item-1x1">
            <GridItemGallery
              images={imagesBottomRight}
              delay={getRandomDelay()}
              duration={getRandomDuration()}
            ></GridItemGallery>
          </div>
        </main>
        <Footer></Footer>
      </div>
    )
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "index" } }) {
      frontmatter {
        title
        description
        imagesLeft {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imagesTopMid {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imagesTopRight {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imagesBottomMid {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imagesBottomRight {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        metaImage {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        # featured_image {
        #   childImageSharp {
        #     fluid {
        #       ...GatsbyImageSharpFluid_withWebp
        #     }
        #   }
        # }
      }
    }
  }
`
