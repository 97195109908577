import Img from "gatsby-image"
import React from "react"
import styles from "./grid-item-gallery.module.scss"

export default class GridItemGallery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.images = this.props.images
    this.numberOfImages = this.images.length
    Object.keys(this.images).forEach(key => {
      this.state["image" + key] = ""
    })
    this.delay = this.props.delay
    this.duration = this.props.duration
    this.intervalDelay = this.delay + this.duration
  }
  images = []
  numberOfImages = 0
  componentDidMount() {
    let ticks = 1
    this.interval = setInterval(() => {
      if (ticks < this.numberOfImages && ticks !== this.numberOfImages) {
        this.setState({
          ["image" + parseInt(this.numberOfImages - ticks)]: "fadeOutGridItem",
        })
      }
      if (ticks === this.numberOfImages) {
        this.setState({
          ["image" + parseInt(this.numberOfImages - 1)]: "fadeInGridItem",
        })
      }
      if (ticks > this.numberOfImages) {
        const newState = {}
        Object.values(this.state).forEach((value, index) => {
          if (value === "fadeOutGridItem") {
            newState["image" + index] = ""
          }
        })
        this.setState({
          ...newState,
          ["image" + parseInt(this.numberOfImages - 1)]: "fadeOutGridItem",
        })
        ticks = 1
      }
      ticks++
    }, this.intervalDelay)
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  buildImages() {
    const placeholderImage = this.images[this.numberOfImages - 1]
    const imagesArray = []
    this.images.forEach((image, index) => {
      imagesArray.push(
        <div
          className={`image ${this.state["image" + index]}`}
          key={index}
          id={`image-${index}`}
          style={{
            animationDuration: `${this.delay / 1000}s`,
            animationDelay: `${this.duration / 1000}s`,
          }}
        >
          <Img fluid={image.childImageSharp.fluid} />
        </div>
      )
    })
    imagesArray.push(
      <div className="placeholder" key={this.numberOfImages}>
        <Img fluid={placeholderImage.childImageSharp.fluid} />
      </div>
    )
    if (imagesArray.length > 2) {
      return imagesArray
    } else {
      return <Img fluid={placeholderImage.childImageSharp.fluid} />
    }
  }
  render() {
    return (
      <div className={`${styles.gridItemGallery} gallery-cross-fade `}>
        {this.buildImages()}
      </div>
    )
  }
}
